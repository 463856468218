<div *ngIf="getCampaign">
    <div *ngIf="screen_desktop" >
            <div class="screenBloqe align-items-center" style=" justify-items: center;"
                [ngClass]="{  'd-none': !screenBloqued,  'd-grid' : screenBloqued }">
                <img loading="lazy"[defaultImage]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'" [lazyLoad]="getCampaign.base_gif_load" width="70px" alt="">
            </div>
            <div class="modal-body modal-lg px-4" style="opacity: {{ opacityModalCheckParameters }} ">
                <div class="content-modal-theme">
                    <div class="container-fluid px-0" id="">
                        <div id="modalBody">
                            <button class="btn-home-hover position-absolute btn-close-pamaters btn-close-confirm-parameters fw-bold text-white" (click)="activeModal.dismiss()">
                                <i class="bi bi-x-lg fw-bold"></i>
                            </button>
                            <div id="info-noticia">
                                <div class="row text-start py-3 px-2">
                                    <div class="col-12">
                                        <div class="w-100 px-2">
                                            <span> Para continuar con {{ bank_opencdt }},</span>
                                            <h2 class="fw-bolder text-blue-100 fs-4 mt-0 lh-1 mb-3">
                                                Verifica que los parámetros de <br> tu inversión estén correctos
                                            </h2>

                                            <div class="alert-info-signature-digital" *ngIf="bank_opencdt == globals_var.MUNDOMUJER || bank_opencdt == globals_var.BANCAMIA">
                                                Si deseas abrir tu CDT rápido y de forma completamente digital, te recomendamos iniciar tu proceso con uno de los bancos marcados con la opción Apertura digital
                                            </div>

                                            <form autocomplete="on" method="POST" [formGroup]="FormCheckParameters" class="mt-2">
                                                <div class="row">
                                                    <div class="col-sm-7 position-relative">
                                                        <span class="fw-bold">Valor de la inversión</span>
                                                        <i class="bi bi-currency-dollar field-icon"></i>
                                                        <input id="amount" type="text" placeholder="Monto a consultar." class="form-control mt-2 text-blue-100 fs-4 fw-bold entry-field-icon"  formControlName="amount" (keyup)="thousandSeparator($event)" (change)="_getSimulationInvestment({
                                                            amount: fCheckParam['amount'].value?.split('.').join(''),
                                                            terms: terms,
                                                            include_only:  [bankID_opencdt]
                                                        })"
                                                        [ngClass]="
                                                            {
                                                                'is-invalid': submitCheckParam && fCheckParam['amount'].value?.split('.').join('') < bank_amount_validate,
                                                                'is-valid' : fCheckParam['amount'].value?.split('.').join('') > bank_amount_validate-1
                                                            }
                                                        "/>
                                                        <div class="invalid-feedback"
                                                        [ngClass]="
                                                            {
                                                                'd-block': submitCheckParam && fCheckParam['amount'].value?.split('.').join('') < bank_amount_validate,
                                                                'd-none' : fCheckParam['amount'].value?.split('.').join('') > bank_amount_validate-1
                                                            }
                                                        ">
                                                            El monto mínimo debe ser igual o mayor a ${{bank_amount_validate | thousandSeparator}} COP
                                                        </div>
                                                    </div>
                                                    <div class="col-5 text-end align-self-end ps-0">
                                                        <span class="fw-bold">Te va a generar</span> <br>
                                                        <span class="fw-light text-black-50 interese-capital" *ngIf="bankID_opencdt === globals_var.ID_FLAMINGO">(intereses + capital)</span>
                                                        <div class="colorAmount fw-bold fs-3">
                                                            ${{ amtGenereteCheckParameters | thousandSeparator }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <hr class="my-3">

                                                <div class="row">
                                                    <div class="col-sm-7 position-relative">
                                                        <span class="fw-bold">Plazo elegido</span>
                                                        <select [(ngModel)]="selectedItem" name="selectedItem" [ngModelOptions]="{standalone: true}" id="" class="form-select fw-bold fs-4 text-blue-100 mt-2" (change)="ChangeSelectValue()">
                                                            <option *ngFor="let item of terms" [ngValue]="item">
                                                                {{ item }} {{ item === 1 ? 'mes' : 'meses' }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-5 text-end align-self-end ps-0">
                                                        <span class="fw-bold">Con una tasa</span>
                                                        <div class="colorAmount fw-bold fs-3">
                                                            {{ placeGenereteCheckParameters }}% EA
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row" *ngIf="bankID_opencdt === globals_var.ID_FLAMINGO">
                                                    <hr class="my-3">
                                                    <div class="col-12 position-relative advisor_tienda " [ngClass]="{'mb-3':was_attended_store}">
                                                        <span class="fw-bold">¿Fuíste atendido en tienda?</span>

                                                        <input type="radio" id="btn-yes" name="button-advisor" (change)="advisorStore(1)">
                                                        <label for="btn-yes" class="me-2">Si</label>

                                                        <input type="radio" id="btn-no" name="button-advisor" (change)="advisorStore(2)">
                                                        <label for="btn-no">No</label>
                                                    </div>
                                                    <div class="col-12 advisor-question position-relative" *ngIf="was_attended_store">
                                                        <span class="fw-bold">Por favor ingresa el número de identificación del asesor</span>
                                                        <i class="bi bi-person-vcard-fill field-icon"></i>
                                                        <input type="text" class="form-control  fw-bold entry-field-icon" formControlName="number_advisor">
                                                    </div>
                                                </div>
                                                <hr class="my-3">

                                                <div class="row">
                                                    <div class="col-sm-7 position-relative">
                                                        <span class="fw-bold">
                                                            {{ getCampaign.components.form_minutes_open_cdt.title_option_bank }}
                                                        </span>
                                                        <img loading="lazy"[defaultImage]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'" [lazyLoad]="bank_image_view" alt="" class="img-thumbnail border-0 mt-2 mt-3">
                                                    </div>
                                                </div>

                                                <div class="row" *ngIf="this.cookies.get('refcode')">
                                                  <div class="col-12 mt-2">
                                                    <div class="d-flex justify-content-between align-items-center bg-blue-100 rounded-3 p-2">
                                                      <img class="w-25" [defaultImage]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'" [lazyLoad]="env.S3_STATIC_FILES + '/images/web/landings/referidos/logo-tooltip.webp'" alt="Gana Gana con MejorCDT" loading="lazy">
                                                      <div class="text-white font-bold text-center text-size-md px-2">
                                                        ¡Estás a un clic de disfrutar tu beneficio!
                                                      </div>
                                                      <div class="text-white text-size-xl px-2">
                                                        <i class="bi bi-arrow-down"></i>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class="row" *ngIf="promoBunos">
                                                    <div class="col-12 mt-2">
                                                      <img [src]="'assets/images/web/bonos/confirmation/banner-promo-simulador-modal-'+cookies.get('promo-bonus')+'.png'" alt="" class="w-100 rounded-2">
                                                    </div>
                                                </div>

                                                <button id="botonEnviarDatos" type="button" class="mt-4 btn btn-lg btn-primary-green text-white w-100" (click)="checkParameters()">
                                                    Todo está bien <i class="bi bi-check2"></i>
                                                </button>

                                                <div class="text-center mt-3">
                                                    <span id="regresar-listado" class="fw-bold fs-5 text-blue-100 pb-2 text-decoration-none cursor-pointer" (click)="activeModal.dismiss()">
                                                        <i class="bi bi-arrow-left"></i>  Ir atrás
                                                    </span>
                                                </div>
                                            </form>

                                            <p class="lh-1 mt-4 mb-0 text-center">
                                                ¿Necesitas ayuda? Escríbenos
                                                <a *ngIf="bankID_opencdt != globals_var.ID_FLAMINGO" href="https://api.whatsapp.com/send?phone=573160234714&&text=Hola,%20estoy%20en%20la%20p%C3%A1gina%20de%20MejorCDT%20escogiendo%20los%20detalles%20de%20mi%20CDT%20y%20quisiera%20hablar%20con%20un%20Asesor" target="_blank" class="text-decoration-none fw-bold text-blue-100">aquí</a>
                                                <a *ngIf="bankID_opencdt === globals_var.ID_FLAMINGO" href="https://m.mejorcdt.com/t/AtencionCDT-Futuro" target="_blank" class="text-decoration-none fw-bold text-blue-100">aquí</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>

    <div *ngIf="showMobile && screen_mobile && bank_allied_mobile " class="check_parameters_mobile">
        <div class="screenBloqe align-items-center" style=" justify-items: center;"
        [ngClass]="{  'd-none': !screenBloqued,  'd-grid' : screenBloqued }">
            <img loading="lazy"[defaultImage]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'" [lazyLoad]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'" alt="">
        </div>
        <div style="opacity: {{ opacityModalCheckParameters }} ">
            <div class="ir-atras-check btn-close-confirm-parameters" (click)="activeModal.dismiss()">
                <i class="bi bi-arrow-left"></i> ir atrás
            </div>

            <div>
                <span> Para continuar con {{ selectedBank }},</span>
                <h2 class="fw-bolder text-blue-100 fs-4 mt-0 lh-1 mb-3 title-verifica-parametros">
                    Verifica que los parámetros de <br> tu inversión estén correctos
                </h2>
            </div>

            <div class="alert-info-signature-digital" *ngIf="bank_opencdt == globals_var.MUNDOMUJER || bank_opencdt == globals_var.BANCAMIA">
                Si deseas abrir tu CDT rápido y de forma completamente digital, te recomendamos iniciar tu proceso con uno de los bancos marcados con la opción Apertura digital
            </div>

            <form autocomplete="on" method="POST" [formGroup]="FormCheckParameters" class="mt-2">
                <div class="row">
                    <div class="col-12 position-relative">
                        <span class="fw-bold">Valor de la inversión</span>
                        <i class="bi bi-currency-dollar field-icon"></i>
                        <input id="amount" type="text" placeholder="Monto a consultar." class="form-control mt-2 text-blue-100 fs-4 fw-bold entry-field-icon"  formControlName="amount" (keyup)="thousandSeparator($event)" (change)="_getSimulationInvestment({
                            amount: fCheckParam['amount'].value?.split('.').join(''),
                            terms: terms,
                            include_only:  [bankID_opencdt]
                        })"
                        [ngClass]="
                            {
                                'is-invalid': submitCheckParam && fCheckParam['amount'].value?.split('.').join('') < bank_amount_validate,
                                'is-valid' : fCheckParam['amount'].value?.split('.').join('') > bank_amount_validate-1
                            }
                        "/>
                        <div class="invalid-feedback"
                        [ngClass]="
                            {
                                'd-block': submitCheckParam && fCheckParam['amount'].value?.split('.').join('') < bank_amount_validate,
                                'd-none' : fCheckParam['amount'].value?.split('.').join('') > bank_amount_validate-1
                            }
                        ">
                            El monto minimo debe ser igual o mayor a ${{bank_amount_validate | thousandSeparator}} COP
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-12 position-relative">
                        <span class="fw-bold">Plazo elegido</span>
                        <select [(ngModel)]="selectedItem" name="selectedItem" [ngModelOptions]="{standalone: true}" id="" class="form-select fw-bold fs-4 text-blue-100 mt-2" (change)="ChangeSelectValue()">
                            <option *ngFor="let item of terms" [ngValue]="item">
                                {{ item }} {{ item === 1 ? 'mes' : 'meses' }}
                            </option>
                        </select>
                    </div>
                </div>


                <div class="row mt-3">
                    <div class="col-sm-7 position-relative">
                        <span class="fw-bold">
                            {{ getCampaign.components.form_minutes_open_cdt.title_option_bank }}
                        </span>
                        <h6 class="text-blue-100 fw-bold fs-4"> {{ selectedBank }} </h6>
                    </div>
                </div>

                <hr class="my-3">

                <div class="row mx-0">
                    <div class="col-6 ps-0">
                        <span class="fw-bold">Te va a generar</span>
                        <div class="fw-light text-black-50 interese-capital" *ngIf="bankID_opencdt === globals_var.ID_FLAMINGO">(intereses + capital)</div>
                        <div class="colorAmount fw-bold fs-3">
                            ${{ amtGenereteCheckParameters | thousandSeparator }}
                        </div>
                    </div>

                    <div class="col-6 text-end align-self-end pe-0">
                        <span class="fw-bold">Con una tasa</span>
                        <div class="colorAmount fw-bold fs-3">
                            {{ placeGenereteCheckParameters }}% EA
                        </div>
                    </div>
                </div>

                <hr class="my-3">

                <div class="row" *ngIf="bankID_opencdt === globals_var.ID_FLAMINGO">
                    <div class="col-12 position-relative advisor_tienda " [ngClass]="{'mb-3':was_attended_store}">
                        <span class="fw-bold">¿Fuíste atendido en tienda?</span>

                        <input type="radio" id="btn-yes" name="button-advisor" (change)="advisorStore(1)">
                        <label for="btn-yes" class="me-2">Si</label>

                        <input type="radio" id="btn-no" name="button-advisor" (change)="advisorStore(2)">
                        <label for="btn-no">No</label>
                    </div>
                    <div class="col-12 advisor-question position-relative" *ngIf="was_attended_store">
                        <span class="fw-bold">Por favor ingresa el número de identificación del asesor</span>
                        <i class="bi bi-person-vcard-fill field-icon"></i>
                        <input type="text" class="form-control  fw-bold entry-field-icon" formControlName="number_advisor">
                    </div>
                </div>

                <div class="row" *ngIf="promoBunos">
                    <div class="col-12 mt-2">
                      <img [src]="'assets/images/web/bonos/confirmation/banner-promo-simulador-modal-'+cookies.get('promo-bonus')+'.png'" alt="" class="w-100 rounded-2">
                    </div>
                </div>

                <div class="row" *ngIf="this.cookies.get('refcode')">
                  <div class="col-12 mt-2">
                    <div class="d-flex justify-content-between align-items-center bg-blue-40 rounded-3 p-2">
                      <img class="w-25" [defaultImage]="env.S3_STATIC_FILES + '/images/web/gif-load-refresh.svg'" [lazyLoad]="env.S3_STATIC_FILES + '/images/web/landings/referidos/logo-tooltip.webp'" alt="Gana Gana con MejorCDT" loading="lazy">
                      <div class="text-blue-100 font-bold text-center text-size-md px-2">
                        ¡Estás a un clic de disfrutar tu beneficio!
                      </div>
                      <div class="text-blue-100 text-size-xl px-2">
                        <i class="bi bi-arrow-down"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="btn-check-mobile">
                    <button id="botonEnviarDatos" type="button" class="btn mt-4 fw-bold fs-4 px-4 w-100 btn btn-lg btn-primary-green" (click)="checkParameters()">
                        Todo está bien <i class="bi bi-check2"></i>
                    </button>

                    <div class="text-center mt-3">
                        <span id="regresar-listado" class="fw-bold fs-5 text-blue-100 pb-2 text-decoration-none cursor-pointer" (click)="activeModal.dismiss()">
                            <i class="bi bi-arrow-left"></i>  Ir atrás
                        </span>
                    </div>



                    <p class="lh-1 mt-4 mb-0 text-center">
                        ¿Necesitas ayuda? Escríbenos
                        <a *ngIf="bankID_opencdt != globals_var.ID_FLAMINGO" href="https://api.whatsapp.com/send?phone=573160234714&&text=Hola,%20estoy%20en%20la%20p%C3%A1gina%20de%20MejorCDT%20escogiendo%20los%20detalles%20de%20mi%20CDT%20y%20quisiera%20hablar%20con%20un%20Asesor" target="_blank" class="text-decoration-none fw-bold text-blue-100">aquí</a>
                        <a *ngIf="bankID_opencdt === globals_var.ID_FLAMINGO" href="https://m.mejorcdt.com/t/AtencionCDT-Futuro" target="_blank" class="text-decoration-none fw-bold text-blue-100">aquí</a>
                    </p>
                </div>

            </form>
        </div>
    </div>
</div>
